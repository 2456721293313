import React from 'react';
import { Link } from 'gatsby';


export const Hero = () => {

  return (
    <div className="py-5" style={{ minHeight: "calc(100vh - 85px)" }}>
      <div className="container">
        <div className="hero">
          <div className="hero__wrap">
            <div className="hero__header">
              <h2 className="hero__lead ">
                Create a functional portfolio websites <br />  that you will be proud of to showcase your work
              </h2>
              <p className="hero__text">
                Easily showcase your work and projects using a portfolio website created on windframe in minutes.
                Access 1000+ editable tailwind template blocks you can easily put together in any form you wish to create the perfect looking portfolio page</p>
              <div className="hero__buttons mb-4">
                <Link href="https://windframe.devwares.com" className="btn1 hero__btn btn__inverse">
                  <span className="btn__text">Create a portfolio</span>
                </Link>
              </div>
            </div>
            <div className="hero__image">
              <video autoPlay muted loop className="feature__img">
                <source src={require('../../../components/images/windheader.mp4')} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
