import React from 'react';
import Helmet from 'react-helmet';

import AppContext, { UserContext } from '../../../components/AppContext';
import NavBar from '../../../components/NavBar/WindframeNav';
import { Footer } from '../../../components/Footer/WindframeFooter';

import { Hero } from '../../../components/Windframe/Portfolio/Hero';
import '../../../styles/scss/style.scss'

const Portfolio = () => {
  return (
    <AppContext>
      <Helmet>
        <title>Portfolio Website - Create a Portfolio website using Windframe </title>
        <meta
          name="title"
          content="Portfolio Website - Create a Portfolio website using Windframe"
        />
        <meta
          name="description"
          content="Create stunning portfolio websites and portfolio pages using windframe . Access 1000+ editable template blocks you can easily put together in any form you wish to create the perfect looking portfolio page"
        />
        <meta
          property="og:title"
          content="Portfolio Website - Create a Portfolio website using Windframe"
        />
        <meta
          property="og:description"
          content="Create stunning portfolio websites and portfolio pages using windframe . Access 1000+ editable template blocks you can easily put together in any form you wish to create the perfect looking portfolio page"
        />
        <meta
          property="twitter:title"
          content="Portfolio Website - Create a Portfolio website using Windframe"
        />
        <meta
          property="twitter:description"
          content="Create stunning portfolio websites and portfolio pages using windframe . Access 1000+ editable template blocks you can easily put together in any form you wish to create the perfect looking portfolio page"
        />
        <link rel="canonical" href="https://windframe.dev/portfolio-website/" />
        <meta property="og:url" content="https://windframe.dev/portfolio-website/" />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            {/* <div className="w-full h-10 px-6 flex items-center justify-center bg-orange-500 text-white">
              <p className="text-xl">
                We are live on product hunt today 🚀 and would appreciate your support!
              </p> */}
            {/* <a href="https://www.producthunt.com/posts/windframe-2-0-2" className="">
                <img
                  src="https://assets.stickpng.com/images/580b57fcd9996e24bc43c534.png"
                  alt="ad-banner"
                  className="rounded-full w-10 h-10"
                />
              </a> */}
            {/* <a
                href="https://www.producthunt.com/posts/windframe-2-0-2"
                className="px-3 text-white underline text-xl font-bold !underline"
                style={{ textDecoration: 'underline' }}
              >
                Support on Product Hunt
              </a>
              </div> */}
            <div className="container">
              <NavBar user={user} />
            </div>
            <Hero />

            <div className="container">
              <Footer />
            </div>
          </div>
        )}
      </UserContext.Consumer>
    </AppContext>
  );
};

export default Portfolio;
